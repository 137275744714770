import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import ShareIcon from '@material-ui/icons/Share';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CalcType,
	CommonStatus,
	CreditCardType,
	OrderDiscountStatus,
	OrderItemDiscountStatus,
	PatronClass,
	PaymentClass,
	PaymentStatus,
	useCreateReceiptAttachmentMutation,
	useGetBillQuery,
	useGetDiscountQuery,
	useGetMenuXDebtorListLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetOfficerQuery,
	useGetReceiptPrinterLazyQuery,
	useGetStaffLazyQuery,
	useGetStaffQuery,
	useGetTaxSchemeDetailLazyQuery,
	useGetTaxSchemeDetailQuery,
	useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import { sortDescByKey } from 'helpers/hooks/stringNumberFunction/sortByKey';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ShareDialog from '../../../../../../components/Dialog/ShareDialog';
import '../../../../BusinessInsights/BusinessAnalytics/bi.scss';
import '../billSettlement.scss';
import {
	BillReceiptDisplay,
	PrinterDialog,
	printReceipt,
	printReceiptText,
} from './BillReceiptFunctions';
import { Overlay } from '@ifca-root/react-component/src/components/Overlay/Overlay';

// import PhoneIcon from '@mui/icons-material/Phone';

export interface PaymentItemProps {
	key?: typeof String;
}

export interface TokenOption {
	discount: Number;
	subtotal: Number;
	serviceCharge: Number;
	tax: Number;
	total: Number;
}

interface OrderItemTax {
	taxAmount: number;
	taxSchemeType: string;
}

interface OrderItemOptionItemTax {
	taxAmount: number;
}

interface OrderItemDiscountTax {
	taxAmount: number;
}

interface OrderItemOptionItemDiscountTax {
	taxAmount: number;
}

interface OrderDiscountItemTax {
	taxAmount: number;
}
interface orderDiscountItemOptionTax {
	taxAmount: number;
}

interface OrderDiscountItem {
	orderItemID?: string;
	baseAmount: number;
	orderDiscountItemTax?: OrderDiscountItemTax[];
}

interface OrderItemDiscount {
	orderItemID?: string;
	baseAmount: number;
	status: string;
	orderItemDiscountTax: OrderItemDiscountTax[];
}
interface OrderItemOptionItem {
	optionItemID: string;
	price: number;
	basePrice: number;
	unitPrice: number;
	orderItemOptionItemTax: OrderItemOptionItemTax[];
	discountBaseAmount: number;
	orderItemOptionItemDiscountTax: OrderItemOptionItemDiscountTax[];
	orderDiscountItemOptionTax: orderDiscountItemOptionTax[];
	// Include other properties if needed
}

interface OrderItemOption {
	optionID: string;
	orderItemOptionItem: OrderItemOptionItem[];
	// Include other properties if needed
}
interface Item {
	ID: string;
	amount: number;
	orderItemTax: OrderItemTax[];
	orderItemDiscount?: OrderItemDiscount;
	orderDiscountItem?: OrderDiscountItem;
	orderItemOption?: OrderItemOption[];
}

export const BillReceipt = (props: any) => {
	useEffect(() => {
		console.log('BillReceipt Outlet App');
	}, []);

	const { module, mode } = props;
	const history = useHistory();
	const { pathname } = useLocation();
	const { [`tableID`]: tableID, outletID, orderID, billID } = useParams<
		Record<string, any>
	>();

	const [hotelGuest, setHotelGuest] = useState({} as any);
	const [nonGuest, setNonGuest] = useState({} as any);
	const [hotelDebtor, setHotelDebtor] = useState({} as any);
	const [officer, setOfficer] = useState({} as any);
	const [staff, setStaff] = useState({} as any);
	const [latestPayment, setLatestPayment] = useState([] as any);
	const [outlet, setOutlet] = useState({} as any);
	const [chargeInfo1, setChargeInfo1] = useState('' as any);
	const [chargeInfo2, setChargeInfo2] = useState('' as any);

	const creditClass = creditClass => {
		let credClass;

		if (creditClass === CreditCardType.AmericanExpressCard) {
			credClass = 'American Express Card';
		} else if (creditClass === CreditCardType.DinersCard) {
			credClass = 'Diners Card';
		} else if (creditClass === CreditCardType.JcbCard) {
			credClass = 'JCB Card';
		} else if (creditClass === CreditCardType.Mastercard) {
			credClass = 'Master Card';
		} else if (creditClass === CreditCardType.UnionpayCard) {
			credClass = 'UNION PAY';
		} else if (creditClass === CreditCardType.VisaCard) {
			credClass = 'Visa Card';
		} else if (creditClass === CreditCardType.DebitCard) {
			credClass = 'Debit Card';
		} else if (creditClass === CreditCardType.Ewallet) {
			credClass = 'E-Wallet';
		}
		return credClass;
	};

	useEffect(() => {
		let info1;

		latestPayment?.map(x => {
			info1 = creditClass(x?.creditCardType);
			if (x?.paymentClass === PaymentClass.Credit) {
				return (
					setChargeInfo1(info1), setChargeInfo2(`****${(x?.cardNo).slice(-4)}`)
				);
			} else if (x?.paymentClass === PaymentClass.Room) {
				return (
					// setChargeInfo1(hotelGuest?.RoomNo),
					setChargeInfo1(x?.hotelGuestInfo?.RoomNo),
					setChargeInfo2(x?.hotelGuestInfo?.FullName)
				);
			} else if (x?.paymentClass === PaymentClass.NonGuest) {
				return (
					setChargeInfo1(null), setChargeInfo2(x?.hotelGuestInfo?.FullName)
				);
			} else if (x?.paymentClass === PaymentClass.SuspendFolio) {
				return (
					setChargeInfo1(null), setChargeInfo2(x?.hotelGuestInfo?.FullName)
				);
			} else if (x?.paymentClass === PaymentClass.Debtor) {
				return (
					setChargeInfo1(x?.hotelGuestInfo?.GuestType),
					setChargeInfo2(x?.hotelGuestInfo?.FullName)
				);
			} else if (x?.paymentClass === PaymentClass.Staff) {
				return (
					setChargeInfo1(staff[0]?.designation), setChargeInfo2(staff[0]?.name)
				);
			} else if (x?.paymentClass === PaymentClass.Officer) {
				return (
					setChargeInfo1(officer[0]?.designation),
					setChargeInfo2(officer[0]?.name)
				);
			} else if (x?.paymentClass === PaymentClass.BankTt) {
				return setChargeInfo1(null), setChargeInfo2(x?.remark);
			}

			return {
				...x,
				chargeInfo1: chargeInfo1,
				chargeInfo2: chargeInfo2,
			};
		});
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [negOrderItem, setNegOrderItem] = useState({});
	const [receiptDataURL, setReceiptDataURL] = useState(null);
	const [baseImage, setBaseImage] = useState(null);
	const [signImage, setSignImage] = useState(null);

	const handleSort = arr => {
		let temp = arr?.slice();
		return sortDescByKey(temp, 'createdTs');
	};

	const [isCash, setIsCash] = useState(false);

	useEffect(() => {
		if (latestPayment?.paymentClass === PaymentClass?.Cash) {
			setIsCash(true);
		}
	}, [latestPayment]);

	const {
		loading: billLoading,
		called: billCalled,
		data: { getBill } = { getBill: [] },
	} = useGetBillQuery({
		fetchPolicy: 'network-only',
		variables: { ID: billID },
		onCompleted: ({ getBill }) => {
			const latestPayment = handleSort(
				getBill[0]?.payments?.filter(x => x?.status === 'OPEN'),
			);

			const outlet = getBill[0]?.outlet;

			//LOGO

			// OLD BASE64
			/*
			var xhr = new XMLHttpRequest();
			xhr.open('GET', `${getBill[0]?.outlet?.logo?.fileURL}`);
			xhr.responseType = 'blob';
			xhr.send();

			var reader = new FileReader();
			xhr.addEventListener('load', function() {
				reader.readAsDataURL(xhr.response);
				reader.addEventListener('loadend', function() {
					setBaseImage(reader.result);
				});
			});
			*/

			// NEW BASE64
			const getBase64FromUrlForOutletLogo = async url => {
				// const data = await fetch(url, { method: 'GET', mode: 'cors', cache: 'no-cache', credentials: 'same-origin', headers: { 'Content-Type': 'application/json', }, });
				const data = await fetch(url);
				const blob = await data.blob();
				return new Promise(resolve => {
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = () => {
						const base64data = reader.result;
						resolve(base64data);
						setBaseImage(reader.result);
					};
				});
			};
			getBase64FromUrlForOutletLogo(`${getBill[0]?.outlet?.logo?.fileURL}`);

			const getBase64FromUrlForSign = async url => {
				const data = await fetch(url);
				const blob = await data.blob();
				return new Promise(resolve => {
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = () => {
						const base64data = reader.result;
						resolve(base64data);
						setSignImage(reader.result);
					};
				});
			};
			getBase64FromUrlForSign(`${getBill[0]?.signature?.fileURL}`);
			// NEW BASE64

			setLatestPayment(latestPayment);
			setOutlet(outlet);
			let temp = {};
			getBill[0]?.orderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};
						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});
			setNegOrderItem({ ...temp });

			latestPayment?.map(x => {
				if (
					outlet?.outletChannel?.hotelID &&
					x?.registrationID &&
					x?.paymentClass === PaymentClass.Debtor
				) {
					loadMenuXDebtor({
						variables: {
							hotelID: outlet?.outletChannel?.hotelID,
							outletID: outletID,
						},
					});
				} else if (
					outlet?.outletChannel?.hotelID &&
					x?.registrationID &&
					(x?.paymentClass === PaymentClass.Room ||
						x?.paymentClass === PaymentClass.NonGuest ||
						x?.paymentClass === PaymentClass.SuspendFolio)
				) {
					loadMenuXInhouse({
						variables: {
							hotelID: outlet?.outletChannel?.hotelID,
							outletID: outletID,
						},
					});
				} else if (x?.paymentClass === PaymentClass.Officer) {
					loadOfficer({
						variables: { ID: x?.patronAccountID },
					});
				} else if (x?.paymentClass === PaymentClass.Staff) {
					loadStaff({
						variables: { ID: x?.patronAccountID },
					});
				}
				// else if (x?.paymentClass === PaymentClass.Cash) {
				// 	loadOfficer({
				// 		variables: { ID: getBill[0]?.order?.patronAccountID },
				// 	});
				// 	loadStaff({
				// 		variables: { ID: getBill[0]?.order?.patronAccountID },
				// 	});
				// } else {
				// 	loadOfficer({
				// 		variables: { ID: getBill[0]?.order?.patronAccountID },
				// 	});
				// 	loadStaff({
				// 		variables: { ID: getBill[0]?.order?.patronAccountID },
				// 	});
				// }
			});
		},
	});

	const [
		loadMenuXInhouse,
		{
			loading: MenuXInhouseListLoading,
			called: MenuXInhouseListCalled,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXInhouseList }) => {
			if (latestPayment?.paymentClass === PaymentClass.Room) {
				setHotelGuest(
					getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
						guest => guest?.RegistrationID === latestPayment?.registrationID,
					)[0],
				);
			} else if (latestPayment?.paymentClass === PaymentClass.NonGuest) {
				setNonGuest(
					getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
						guest => guest?.RegistrationID === latestPayment?.registrationID,
					)[0],
				);
			}
		},
	});

	const [
		loadMenuXDebtor,
		{
			loading: MenuXDebtorListLoading,
			called: MenuXDebtorListCalled,
			data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
		},
	] = useGetMenuXDebtorListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXDebtorList }) => {
			setHotelDebtor(
				getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
					guest => guest?.RegistrationID === latestPayment?.registrationID,
				)[0],
			);
		},
	});

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	const [
		loadReceiptPrinter,
		{
			loading: receiptPrinterLoading,
			called: receiptPrinterCalled,
			data: { getReceiptPrinter } = { getReceiptPrinter: [] },
		},
	] = useGetReceiptPrinterLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getReceiptPrinter }) => {
			if (
				mode === 'bill-printing' ||
				mode === 'void-payment' ||
				mode === 'void-bill' ||
				mode === 'digital-document'
			) {
				printReceiptText(
					baseImage,
					outlet,
					getBill[0],
					mode,
					orderItems,
					negOrderItem,
					{
						discount: fd,
						subtotal: fs,
						serviceCharge: sc,
						tax: ta,
						total: to,
					},
					getDiscount,
					latestPayment,
					chargeInfo1,
					chargeInfo2,
					outlet?.currencyCode ?? '',
					optDisc,
					patronName,
					// signImage,
					getReceiptPrinter[0]?.name,
					module,
					isCash,
					'bill-receipt',
					getTaxSchemeDetail,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					getUsersByAccountAndSoftware,
					officer,
					staff,
				);
			} else if (mode === 'dineIn' || mode === 'takeAway') {
				if (getReceiptPrinter?.length > 0) {
					printReceiptText(
						baseImage,
						outlet,
						getBill[0],
						mode,
						orderItems,
						negOrderItem,
						{
							discount: fd,
							subtotal: fs,
							serviceCharge: sc,
							tax: ta,
							total: to,
						},
						getDiscount,
						latestPayment,
						chargeInfo1,
						chargeInfo2,
						outlet?.currencyCode ?? '',
						optDisc,
						patronName,
						// signImage,
						getReceiptPrinter[0]?.name,
						module,
						isCash,
						'bill-receipt',
						getTaxSchemeDetail,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						getUsersByAccountAndSoftware,
						officer,
						staff,
					);
				} else if (getReceiptPrinter?.length === 0) {
					localPrinter();
				}
			}

			/* By Default: 
			Receipt printer should only be printed at the main terminal
			 - Receipt printer cvan be set up through the db under ReceiptPrinter
			*/
		},
	});

	const [
		loadOfficer,
		{
			loading: getOfficerLoading,
			called: getOfficerCalled,
			data: { getOfficer } = { getOfficer: {} as any },
		},
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOfficer }) => {
			setOfficer(getOfficer);
			setPatronName(getOfficer[0]?.name);
		},
	});

	const [
		loadStaff,
		{
			loading: getStaffLoading,
			called: getStaffCalled,
			data: { getStaff } = { getStaff: {} as any },
		},
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getStaff }) => {
			setStaff(getStaff);
			setPatronName(getStaff[0]?.name);
		},
	});

	const {
		loading: getDiscountLoading,
		called: getDiscountCalled,
		data: { getDiscount } = { getDiscount: [] },
	} = useGetDiscountQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const patronClass = getBill[0]?.order?.patron?.patronClass;
	const patronAccountID = getBill[0]?.order?.patronAccountID;

	const [patronName, setPatronName] = useState('');

	useEffect(() => {
		if (patronClass === 'Officer') {
			loadOfficer({
				variables: { ID: getBill[0]?.order?.patronAccountID },
			});

			// const officer = getOfficer?.filter(x => x?.ID === patronAccountID)[0]
			// 	?.name;
			// setPatronName(officer);
		} else if (patronClass === 'Staff') {
			loadStaff({
				variables: { ID: getBill[0]?.order?.patronAccountID },
			});
			// const staff = getStaff?.filter(x => x?.ID === patronAccountID)[0]?.name;
			// setPatronName(staff);
		} else if (patronClass === 'Hotel Guest' || patronClass === 'Visitor') {
			setPatronName(getBill[0]?.order?.patronName || `-`);
		}
	}, [patronClass]);

	const orderItems = getBill[0]?.orderItem
		?.map(el => {
			if (
				el?.quantity - negOrderItem?.[el?.ID]?.quantity === 0 ||
				el?.quantity < 0
			) {
				return { ...el, void: true };
			} else {
				return { ...el, void: false };
			}
		})
		?.filter(list => list.void === false);

	const fixOrderItems = getBill[0]?.orderItem;

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const [
		createAttachment,
		{
			data: { createReceiptAttachment } = {
				createReceiptAttachment: {} as any,
			},
			called: createReceiptAttachmentCalled,
			loading: createReceiptAttachmentLoading,
		},
	] = useCreateReceiptAttachmentMutation({
		// onCompleted: () => {
		// 	setOpenShareDialog(true);
		// },
	});

	const onSubmit = blob => {
		createAttachment({
			variables: {
				file: blob,
				billID: billID,
				outletID: outletID,
			},
		});
	};

	const shareReceiptRef = useRef(null);

	useEffect(() => {
		if (openShareDialog) {
			const timeoutId = setTimeout(() => {
				if (shareReceiptRef.current) {
					const attachment = shareReceiptRef.current;

					htmlToImage
						.toBlob(attachment, {
							style: { background: '#ffffff' },
							cacheBust: true,
						})
						.then(value => {
							onSubmit(value);
						})
						.catch(error => {
							console.error('Error generating image blob:', error);
						});
				} else {
					console.error('Element with id "share-receipt" not found.');
				}
			}, 100); // Delay execution to allow for rendering

			return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
		}
	}, [openShareDialog]);

	console?.log(shareReceiptRef, 'shareReceiptRef');

	//CALCULATION

	let ttl,
		subtotal,
		finalServiceCharge,
		finalTax,
		total,
		discount,
		totalOptItm,
		discOptItm,
		optTaxAmount,
		discOptTaxAmount,
		discOptServiceCharge,
		totalOptItmPerOrderItemAll,
		orderDiscount;
	if (orderItems?.length > 0) {
		const itemArray = getBill[0]?.orderItem;

		//convert and fetch important data

		const filterItemArray: {
			ID: string;
			amount: number;
			orderItemTax: OrderItemTax[];
			orderItemDiscount?: OrderItemDiscount;
			orderDiscountItem?: OrderDiscountItem;
			orderItemOption?: OrderItemOption[];
		}[] = itemArray.map(
			({
				ID,
				amount,
				orderItemTax,
				orderItemDiscount,
				orderDiscountItem,
				orderItemOption,
			}) => {
				const activeOID = orderItemDiscount?.filter(
					x => x?.status === OrderItemDiscountStatus.Active,
				)[0];

				return {
					ID,
					amount,
					orderItemTax: orderItemTax?.map(({ taxAmount, taxSchemeType }) => ({
						taxAmount,
						taxSchemeType,
					})),
					orderItemDiscount: {
						baseAmount: activeOID?.baseAmount ?? 0,
						status: activeOID?.status ?? '',
						orderItemID: ID,
						orderItemDiscountTax: activeOID
							? activeOID?.orderItemDiscountTax?.map(({ taxAmount }) => ({
									taxAmount,
							  }))
							: [{ taxAmount: 0 }],
					},
					orderDiscountItem: {
						baseAmount: orderDiscountItem?.baseAmount ?? 0,
						orderItemID: ID,
						orderDiscountItemTax: orderDiscountItem?.orderDiscountItemTax
							? orderDiscountItem?.orderDiscountItemTax?.map(
									({ taxAmount }) => ({
										taxAmount,
									}),
							  )
							: [{ taxAmount: 0 }],
					},
					orderItemOption: orderItemOption?.map(
						({ optionID, orderItemOptionItem }) => ({
							optionID,
							orderItemOptionItem: orderItemOptionItem.map(
								({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax,
									discountBaseAmount,
									orderItemOptionItemDiscountTax,
									orderDiscountItemOptionTax,
								}) => ({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax: orderItemOptionItemTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									discountBaseAmount,
									orderItemOptionItemDiscountTax: orderItemOptionItemDiscountTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									orderDiscountItemOptionTax: orderDiscountItemOptionTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									// Include other properties if needed
								}),
							),
							// Include other properties from OrderItemOption if needed
						}),
					),
				};
			},
		);

		//subtotal amount
		const sumAmount = (items: Item[]): number => {
			let totalBaseAmount = 0;
			for (const item of items) {
				totalBaseAmount += item.amount;
			}
			return totalBaseAmount;
		};

		const totalAmount = sumAmount(filterItemArray);

		//subtotal tax amount

		const sumTaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemTax) {
					totalTax += tax?.taxAmount;
				}
			}
			return totalTax;
		};

		const totalTaxAmount = sumTaxAmount(filterItemArray);
		const roundedTotalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

		//subtotal order discount item base amount

		const sumODIBaseAmount = (items: Item[]): number => {
			let totalODIBaseAmt = 0;
			for (const item of items) {
				totalODIBaseAmt += item?.orderDiscountItem?.baseAmount;
			}
			return totalODIBaseAmt;
		};

		const totalODIBaseAmount = sumODIBaseAmount(filterItemArray);

		//subtotal order discount item tax amount

		const sumODITaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderDiscountItem?.orderDiscountItemTax)
					totalTax += tax?.taxAmount;
			}
			return totalTax;
		};

		const totalODITaxAmount = sumODITaxAmount(filterItemArray);
		const roundedTotalODITaxAmount = parseFloat(totalODITaxAmount.toFixed(2));

		//subtotal order item discount base amount
		const sumOIDBaseAmount = (items: Item[]): number => {
			let totalOIDBaseAmt = 0;
			for (const item of items) {
				totalOIDBaseAmt += item?.orderItemDiscount?.baseAmount;
			}
			return totalOIDBaseAmt;
		};

		const totalOIDBaseAmount = sumOIDBaseAmount(filterItemArray);

		//subtotal order item discount tax amount

		const sumOIDTaxAmount = (items: Item[]): number => {
			let totalOIDTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemDiscount?.orderItemDiscountTax) {
					totalOIDTax += tax?.taxAmount;
				}
			}
			return totalOIDTax;
		};

		const totalOIDTaxAmount = sumOIDTaxAmount(filterItemArray);
		const roundedTotalOIDTaxAmount = parseFloat(totalOIDTaxAmount.toFixed(2));

		//overall option item important field to sum up
		const sumOptionItemOverallField = (
			items: Item[],
		): {
			basePrice: number;
			taxAmount: number;
			discountBaseAmount: number;
			itemDiscountTaxAmount: number;
			discountItemTaxAmount: number;
		} => {
			let totalBasePriceOpt = 0;
			let totalTaxAmountOpt = 0;
			let totalDiscBaseAmountOpt = 0;
			let totalItemDiscTaxAmountOpt = 0;
			let totalDiscItemTaxAmountOpt = 0;
			for (const item of items) {
				if (item.orderItemOption) {
					for (const option of item.orderItemOption) {
						if (option.orderItemOptionItem) {
							for (const optionItem of option.orderItemOptionItem) {
								let totalTax = 0;
								optionItem?.orderItemOptionItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								let totalOptItemOIDTax = 0;
								optionItem?.orderItemOptionItemDiscountTax?.map(x => {
									totalOptItemOIDTax += x?.taxAmount;
								});

								let totalOptItemODITax = 0;
								optionItem?.orderDiscountItemOptionTax?.map(v => {
									totalOptItemODITax += v?.taxAmount;
								});

								totalBasePriceOpt += optionItem.basePrice;
								totalTaxAmountOpt += totalTax;
								totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
								totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
								totalDiscItemTaxAmountOpt += totalOptItemODITax;
							}
						}
					}
				}
			}
			return {
				basePrice: totalBasePriceOpt,
				taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
				discountBaseAmount: totalDiscBaseAmountOpt,
				itemDiscountTaxAmount: parseFloat(totalItemDiscTaxAmountOpt.toFixed(2)),
				discountItemTaxAmount: parseFloat(totalDiscItemTaxAmountOpt.toFixed(2)),
			};
		};

		const sumOptionItemPerOrderItem = (
			items: Item[],
		): {
			orderItemID: string;
			basePrice: number;
			taxAmount: number;
			discountBaseAmount: number;
			itemDiscountTaxAmount: number;
			discountItemTaxAmount: number;
		}[] => {
			const resultArray = [];

			items?.forEach(x => {
				let totalBasePriceOpt = 0;
				let totalTaxAmountOpt = 0;
				let totalDiscBaseAmountOpt = 0;
				let totalItemDiscTaxAmountOpt = 0;
				let totalDiscItemTaxAmountOpt = 0;
				let orderitemIDOpts = x?.ID;

				if (x.orderItemOption) {
					for (const option of x.orderItemOption) {
						if (option.orderItemOptionItem) {
							for (const optionItem of option.orderItemOptionItem) {
								let totalTax = 0;
								optionItem?.orderItemOptionItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								let totalOptItemOIDTax = 0;
								optionItem?.orderItemOptionItemDiscountTax?.map(x => {
									totalOptItemOIDTax += x?.taxAmount;
								});

								let totalOptItemODITax = 0;
								optionItem?.orderDiscountItemOptionTax?.map(v => {
									totalOptItemODITax += v?.taxAmount;
								});

								totalBasePriceOpt += optionItem.basePrice;
								totalTaxAmountOpt += totalTax;
								totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
								totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
								totalDiscItemTaxAmountOpt += totalOptItemODITax;
							}
						}
					}
				}

				resultArray.push({
					orderItemID: orderitemIDOpts,
					basePrice: totalBasePriceOpt,
					taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
					discountBaseAmount: totalDiscBaseAmountOpt,
					itemDiscountTaxAmount: parseFloat(
						totalItemDiscTaxAmountOpt.toFixed(2),
					),
					discountItemTaxAmount: parseFloat(
						totalDiscItemTaxAmountOpt.toFixed(2),
					),
				});
			});

			return resultArray;
		};

		totalOptItmPerOrderItemAll = sumOptionItemPerOrderItem(filterItemArray);

		const totalOptItmOverallAmount = sumOptionItemOverallField(filterItemArray);

		optTaxAmount = totalOptItmOverallAmount?.taxAmount;

		discOptTaxAmount =
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		totalOptItm =
			totalOptItmOverallAmount?.basePrice + totalOptItmOverallAmount?.taxAmount;

		discOptItm =
			totalOptItmOverallAmount?.discountBaseAmount +
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		//tax
		finalTax =
			roundedTotalTaxAmount +
			optTaxAmount -
			roundedTotalODITaxAmount -
			roundedTotalOIDTaxAmount -
			discOptTaxAmount;

		//subtotal
		subtotal = totalAmount + roundedTotalTaxAmount + totalOptItm;

		//discount
		let ODI = totalODIBaseAmount + roundedTotalODITaxAmount;

		let OID = totalOIDBaseAmount + roundedTotalOIDTaxAmount;

		discount = ODI + OID + discOptItm;

		//total
		total = subtotal - discount;
	}

	const [fs, setFS] = useState(null);
	const [sc, setSC] = useState(null);
	const [ta, setTa] = useState(null);
	const [fd, setFD] = useState(null);
	const [to, setTo] = useState(null);
	const [optDisc, setOptDisc] = useState(null);

	useEffect(() => {
		setFS(subtotal);
		setSC(finalServiceCharge);
		setTa(finalTax);
		setFD(discount);
		setTo(total);
		setOptDisc(totalOptItmPerOrderItemAll);
	}, [
		subtotal,
		finalServiceCharge,
		finalTax,
		discount,
		total,
		// totalOptItmPerOrderItemAll,
	]);

	const [orderPatron, setOrderPatron] = useState(null);

	const filterOrder = data => {
		return data?.filter(
			v => v?.orderItem?.filter(e => e?.billID === null).length > 0,
		);
	};

	//Printer

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		data: { getTaxSchemeDetail } = {
			getTaxSchemeDetail: null,
		},
		loading: getTaxSchemeDetailLoading,
	} = useGetTaxSchemeDetailQuery({
		fetchPolicy: 'no-cache',
		variables: {
			accountID: user?.accountID,
			taxSchemeID: outlet?.taxSchemeID,
			orderID: getBill[0]?.order?.ID,
		},
	});

	const [openPrinterDialog, setOpenPrinterDialog] = useState(false);
	const [printer, setPrinter] = useState(false);

	const handlePrinting = () => {
		loadReceiptPrinter({
			variables: {
				outletID: outletID,
				isDefault: true,
			},
		});
	};

	const bixolonPrinter = () => {
		handlePrinting();
	};

	const localPrinter = () => {
		setOpenPrinterDialog(false);
		let temp = document.getElementById('share-receipt');
		htmlToImage
			.toPng(temp, {
				style: { background: '#ffffff' },
				cacheBust: true,
			})
			.then(x => {
				const win = window.open('', '');
				win.document.write('<html><head>');
				win.document.write('</head><body >');
				win.document.write('<img id="print-image-element" src="' + x + '"/>');
				win.document.write(
					'<script>var img = document.getElementById("print-image-element"); img.addEventListener("load",function(){ window.focus(); window.print(); window.document.close(); window.close(); }); </script>',
				);
				win.document.write('</body></html>');
				win.document?.close();
				setTimeout(() => {
					win.window.print();
				}, 100);
			})
			.catch(error => {
				console.error('Printing failed', error);
			});
	};

	return (
		<>
			{billLoading && <Loading />}
			{createReceiptAttachmentLoading && <Loading />}
			{getStaffLoading && <Loading />}
			{getOfficerLoading && <Loading />}
			{MenuXDebtorListLoading && <Loading />}
			{MenuXInhouseListLoading && <Loading />}
			{getDiscountLoading && <Loading />}
			{receiptPrinterLoading && <Loading />}

			<div className={'example-screen'}>
				<MainHeader
					onClick={() => {
						history.goBack();
					}}
					mainBtn="back"
					smTitle="Outlet App"
					title={outlet?.name}
					routeSegments={[
						{ name: 'Table Layout' },
						{ name: 'Table 03 (HARDCODE)' },
						{ name: 'Bill', current: true },
						{ name: 'Receipt', current: true },
					]}
				/>
			</div>

			<div id="share-receipt" ref={shareReceiptRef}>
				<ContentWrapper footer float>
					{!billLoading && (
						<>
							{mode == 'void-payment' ? (
								<>
									<BillReceiptDisplay
										outlet={outlet}
										outletLogo={baseImage}
										signImage={signImage}
										data={getBill}
										orderItems={orderItems}
										footerToken={{
											discount: fd,
											subtotal: fs,
											serviceCharge: sc,
											tax: ta,
											total: to,
										}}
										negOrderItem={negOrderItem}
										discount={getDiscount}
										hidden={false}
										mode={'void-payment'}
										hotelGuest={hotelGuest}
										hotelDebtor={hotelDebtor}
										officer={officer}
										staff={staff}
										latestPayment={latestPayment}
										chargeInfo1={chargeInfo1}
										chargeInfo2={chargeInfo2}
										currencyCode={outlet?.currencyCode ?? ''}
										totalOptItmPerOrderItem={optDisc}
										outletID={outletID}
										patronName={patronName}
									/>
								</>
							) : mode == 'void-bill' ? (
								<>
									<BillReceiptDisplay
										outlet={outlet}
										outletLogo={baseImage}
										signImage={signImage}
										data={getBill}
										orderItems={orderItems}
										footerToken={{
											discount: fd,
											subtotal: fs,
											serviceCharge: sc,
											tax: ta,
											total: to,
											// optDisc: optDisc,
										}}
										negOrderItem={negOrderItem}
										discount={getDiscount}
										hidden={false}
										mode={'void-bill'}
										hotelGuest={hotelGuest}
										hotelDebtor={hotelDebtor}
										officer={officer}
										staff={staff}
										latestPayment={latestPayment}
										chargeInfo1={chargeInfo1}
										chargeInfo2={chargeInfo2}
										currencyCode={outlet?.currencyCode ?? ''}
										totalOptItmPerOrderItem={optDisc}
										outletID={outletID}
										patronName={patronName}
									/>
								</>
							) : (
								<>
									<BillReceiptDisplay
										outlet={outlet}
										outletLogo={baseImage}
										signImage={signImage}
										data={getBill}
										orderItems={orderItems}
										footerToken={{
											discount: fd,
											subtotal: fs,
											serviceCharge: sc,
											tax: ta,
											total: to,
											// optDisc: optDisc,
										}}
										negOrderItem={negOrderItem}
										discount={getDiscount}
										hidden={false}
										mode={''}
										hotelGuest={hotelGuest}
										hotelDebtor={hotelDebtor}
										officer={officer}
										staff={staff}
										latestPayment={latestPayment}
										chargeInfo1={chargeInfo1}
										chargeInfo2={chargeInfo2}
										currencyCode={outlet?.currencyCode ?? ''}
										totalOptItmPerOrderItem={optDisc}
										outletID={outletID}
										patronName={patronName}
									/>
								</>
							)}
						</>
					)}
				</ContentWrapper>
			</div>

			{createReceiptAttachmentCalled &&
				!createReceiptAttachmentLoading &&
				ShareDialog({
					shareTitle: `Share Receipt - ${getBill[0]?.order?.docNo}`,
					title: `Share Receipt`,
					URL: createReceiptAttachment?.fileURL,
					setSimple: setOpenShareDialog,
					simple: openShareDialog,
					emailShare: openSendEmail,
					setSimpleEmail: setOpenSendEmail,
				})}

			<div className={'example-screen'}>
				<DynamicFooter
					overlap
					isHidden={openShareDialog}
					options={[
						{
							name:
								filterOrder(getBill[0]?.order?.orderItem)?.length === 0
									? 'Home'
									: 'Back',
							onClick: () => {
								if (mode === 'takeAway') {
									history.push(`/menu/outlet-app/${outletID}/takeaway`);
								} else {
									module === 'bill-settlement'
										? history.push({
												pathname: `/menu/outlet-app/${outletID}/table/grid`,
										  })
										: history.push({
												pathname: `/menu/outlet-app/${outletID}/bill-listing`,
										  });
								}
							},

							color: 'primary',
						},
						{
							name: 'Print',
							onClick: () => {
								if (
									mode === 'bill-printing' ||
									mode === 'void-payment' ||
									mode === 'void-bill' ||
									mode === 'digital-document'
								) {
									setOpenPrinterDialog(true);
								} else if (mode === 'dineIn' || mode === 'takeAway') {
									handlePrinting();
								}
							},
							className: 'noPrint',
							color: 'primary',
							props: {
								type: 'submit',
							},
						},
						{
							name: 'Share',
							onClick: () => {
								setOpenShareDialog(true);
							},
							color: 'primary',
							props: {
								type: 'submit',
							},
							startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
						},
					]}
				/>
			</div>

			<PrinterDialog
				openPrinterDialog={openPrinterDialog}
				setOpenPrinterDialog={setOpenPrinterDialog}
				printer={printer}
				setPrinter={setPrinter}
				bixolonPrinter={bixolonPrinter}
				localPrinter={localPrinter}
			/>
		</>
	);
};
